import '~/components/App/App.polyfills'
import { createRoot } from 'react-dom/client';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { Logger } from './helpers/LoggingHelper';
import { loginRequest } from './authConfig';
import { AuthHelper } from './authHelper';
import { App } from './components/App';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const init = async () => {
  const msalInstance = AuthHelper.getMsal();

  msalInstance.addEventCallback(async (event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
      if (event.error?.name === 'InteractionRequiredAuthError') {
        msalInstance.loginRedirect(loginRequest);
      } else {
        Logger.error('ACQUIRE_TOKEN_FAILURE');
      }
    } else if (event.eventType === EventType.LOGIN_FAILURE) {
      if (event.error?.name === 'BrowserAuthError') {
        msalInstance.loginRedirect(loginRequest);
      } else {
        Logger.error('LOGIN FAILURE');
      }
    }
  });

  await msalInstance.initialize();

  const [account] = msalInstance.getAllAccounts();
  if (account) {
    msalInstance.setActiveAccount(account);
  }

  await msalInstance.handleRedirectPromise();

  return {
    msalInstance,
  }
};

init().then(({ msalInstance }) => {
  root.render(<App instance={msalInstance} />);
})

// This is necessary for HMR to work.
// if (module.hot) module.hot.accept();
