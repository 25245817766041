import { useContext, useEffect, useMemo, useState } from 'react';
import { useLoaderData } from '~/hooks';
import { AssessmentService, ContentService, ContentType, StorageService } from '~/services';
import { IAssessment } from '~/models/Assessment';
import { CapabilityModel } from '~/models/Capability';
import { AuthContext, DataContext } from '~/components/Context';
import { Tag } from '~/components/Context/DataContext';
import { Assessments } from '~/components/Assessments';
import { CapabilityInfo } from '~/components/CapabilityInfo';
import { Competencies } from '~/components/Competencies';
import './SelfAssessment.scss';

export interface ISelfAssessmentLoaderData {
  assessment?: IAssessment;
  isSelfAssessment: boolean;
  isAssessmentComplete: boolean;
}

export const SelfAssessmentLoader = async (data: any): Promise<ISelfAssessmentLoaderData> => {
  const contentService = new ContentService();
  const storageService = new StorageService();

  // clear cache
  const capabilities = await contentService?.getContentType(CapabilityModel, ContentType.Capability);
  capabilities?.forEach(capability => {
    const tag = Tag[capability.slug];
    storageService.removeItem(tag);
  });

  // set assessment data
  let assessment;
  const { assessmentId } = data?.params;

  if (assessmentId) {
    const assessmentService = new AssessmentService();
    assessment = await assessmentService.getAssessment(assessmentId);
  }

  // const tag = Tag[capabilityName];
  // const content = await new ContentService().getContentByTag(tag);
  // await storageService.setItem(tag, content);

  const key = 'selfAssessment';
  storageService.setItem(key, { capabilities });

  return {
    assessment,
    isSelfAssessment: true,
    isAssessmentComplete: !!assessment,
  };
}

const SelfAssessment = () => {
  const dataContext = useContext(DataContext);
  const [_assessment, setAssessment] = dataContext.assessmentState;
  const [_assessments, setAssessments] = dataContext.assessmentsState;
  const [showAssessments, setShowAssessments] = dataContext.showAssessmentsState;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { assessment } = useLoaderData();

  useEffect(() => {
    const loadAssessments = async () => {
      const assessmentService = new AssessmentService();
      const assessmentList = await assessmentService.getAssessmentList(user.localAccountId);

      setAssessments(assessmentList);
    };

    // on load, clear out any existing data
    // dataContext.reset();

    // get assessments
    loadAssessments();
  }, []);

  useEffect(() => {
    if (assessment) {
      setAssessment(assessment);
    }
  }, [assessment])

  useMemo(() => {
    if (showAssessments) {
      setDrawerOpen(true);
    }
  }, [showAssessments]);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setShowAssessments(false);
  }

  return (
    <section id="assessment">
      <CapabilityInfo />
      <div className="spacer"></div>
      <Competencies />
      <Assessments open={drawerOpen} onClose={handleDrawerClose} />
    </section>
  )
}

export default SelfAssessment;
