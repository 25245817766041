import { FeedOptions } from '@azure/cosmos';
import { Env } from '~/services';
import { AssessmentModel, IAssessment } from '~/models/Assessment';
import { IKeyValuePair } from '~/components/Context';
import { AuthHelper } from '~/authHelper';
import { DataClient, DataHelper, IdHelper } from './util';
import { BaseCollection, IQueryResults } from './Base';
import { CapabilityModel, CompetencyModel, SkillModel, TitleModel } from '~/models';
import { Logger } from '~/helpers/LoggingHelper';

let AssessmentRepositoryInstance: AssessmentRepository;

class AssessmentRepository extends BaseCollection {
  public static readonly collectionName = 'Assessments';
  public static readonly transform = (data: any) => {
    const model = new AssessmentModel();
    model.id = data.id;
    model.Name = data.Name;
    model.Capability = new CapabilityModel(data.Capability);
    model.Competencies = data.Competencies.map(c => {
      const competency = new CompetencyModel(c)
      competency.comments = c.comments || [];
      competency.skills = c.skills.map(s => {
        const skill = new SkillModel(s);
        skill.assessment= s.assessment;
        skill.value = s.value;
        return skill;
      });
      return competency;
    });
    model.CreatedDate = new Date(data.CreatedDate);
    model.Title = new TitleModel(data.Title);
    model.UserId = data.UserId;
    return model;
  }
  private data: IAssessment[] = [];

  get env() {
    return Env('ENVIRONMENT');
  }

  static Instance(): AssessmentRepository {
    if (!AssessmentRepositoryInstance) {
      const msalInstance = AuthHelper.getMsal();
      const client = DataClient.Instance(msalInstance.getActiveAccount());
      AssessmentRepositoryInstance = new AssessmentRepository(client);
    }

    return AssessmentRepositoryInstance;
  }

  constructor(client: DataClient) {
    super(
      client,
      AssessmentRepository.collectionName,
      AssessmentRepository.transform,
    );
    this.init();
  }

  // partitionKey = Capability.id
  async deleteById(id: string, partitionKey: string) {
    if (this.env === 'local') {
      const index = this.data.findIndex(item => item.id === id);
      if (index > -1) {
        this.data.splice(index, 1);
        return true;
      }
      throw new Error('Not Found');

    }

    return super.deleteById(id, partitionKey);
  }

  async getAll(): Promise<IQueryResults<IAssessment>> {
    if (this.env === 'local') {
      return { results: this.data };
    }

    return super.getAll();
  }

  async getById(id: string): Promise<IAssessment> {
    if (this.env === 'local') {
      const { results: items } = DataHelper.query(this.data,
        `SELECT * FROM root r WHERE r.id=@id`,
        {
          '@id': id,
        }
      );

      return items.firstOrDefault();
    }

    return super.getById(id);
  }

  async query(query: string, params?: IKeyValuePair<any>, options?: FeedOptions): Promise<IQueryResults<IAssessment>> {
    if (this.env === 'local') {
      return new Promise(resolve => {
        const expression = DataHelper.parseQuery(query, params);
        const items = this.data.filter(expression);
        resolve({
          results: items
        });
      });
    }

    return super.query(query, params, options);
  }

  async searchByField(fieldName: string, query: string, additionalQueries: IKeyValuePair<any> = {}): Promise<IQueryResults<IAssessment>> {
    if (this.env === 'local') {
      return this.query(
        `SELECT *
         FROM root r
         WHERE CONTAINS(UPPER(ToString(r.${fieldName})), @param)${[
           '', ...Object.keys(additionalQueries).map((key, index) => `ToString(r.${key})=@param${index}`)
          ].join(' AND ')}`,
        {
          '@param': query.toUpperCase(),
          ...Object.keys(additionalQueries).reduce((acc: object, key: string, index: number) => ({
            ...acc,
            [`@param${index}`]: additionalQueries[key].toString()
          }), {})
        }
      );
    }

    return super.searchByField(fieldName, query, additionalQueries);
  }

  async upsert(item: IAssessment): Promise<IAssessment> {
    if (!item.id) {
      item.id = new IdHelper().randomId();
    }

    if (this.env === 'local') {
      return new Promise(resolve => {
        const index = this.data.findIndex(x => x.id === item.id);
        if (index > -1) {
          this.data[index] = item;
        } else {
          this.data.push(item);
        }

        resolve(item);
      });
    }

    return super.upsert(item);
  }

  private init() {
    if (this.env === 'local') {
      Logger.info('Initializing Assessment data...');

      const records = 5;
      for (let index = 0; index < records; index++) {
        this.data.push(AssessmentModel.GenerateAssessment(this.client.user));
      }
    }
  }
}

export default AssessmentRepository
