import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import { Delete, OpenInBrowser } from '@mui/icons-material';
import { useMsal } from '@azure/msal-react';
import { useInputValue, useLoaderData } from '~/hooks';
import { IAssessment } from '~/models';
import { ISelfAssessmentLoaderData } from '~/pages/SelfAssessment';
import { AssessmentService } from '~/services';
import { AuthContext, DataContext } from '~/components/Context';
import { useHandlers } from './Assessments.handlers';
import './Assessments.scss';

interface IAssessmentsModel {
  open: any;
  onClose: any;
}

const Assessments: React.FC<IAssessmentsModel> = ({ open, onClose }) => {
  const msal = useMsal();
  const navigate = useNavigate();
  const { assessmentId } = useParams();
  const { isSelfAssessment } = useLoaderData<ISelfAssessmentLoaderData>();
  const assessmentService = useRef(new AssessmentService());
  const authContext = useContext(AuthContext);
  const dataContext = useContext(DataContext);
  const [assessments] = dataContext.assessmentsState;
  const [capability] = dataContext.capabilityState;
  const [competencies] = dataContext.competenciesState;
  const [title] = dataContext.titleState;
  const [assessmentData, setAssessmentData] = useState<IAssessment>(undefined);
  const [rename, setRename] = useState(false);
  const renameInput = useInputValue<string>()
  const {
    handleClose,
    handleDelete,
    handleDrawerClick,
    handleOpen,
  } = useHandlers({
    assessmentData,
    assessmentService,
    authContext,
    dataContext,
    navigate,
    onClose,
    rename,
    renameInput,
    setAssessmentData,
    setRename,
  });

  useEffect(() => {
    if (isSelfAssessment && title) {
      setAssessmentData({
        Capability: capability,
        Competencies: competencies,
        CreatedDate: new Date(),
        Title: title,
        UserId: msal.accounts.firstOrDefault()?.localAccountId,
        Name: title.name,
      });
      renameInput.onChange({ target: { value: title.name } });
    }
  }, [isSelfAssessment, title])

  const assessmentItem = (assessment: IAssessment) => {
    return (
      <ListItemText>
        <Tooltip
          title={assessment.Capability.name}
          placement="right"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -35],
                  },
                },
              ],
            },
          }}
        >
          <span className='name'>{assessment.Name}</span>
        </Tooltip>
        <span className='createdDate'>
          {new Intl.DateTimeFormat(navigator.language).format(assessment.CreatedDate)}
        </span>
      </ListItemText>
    )
  };

  return (
    <Drawer
      id="assessments"
      anchor="right"
      open={open}
      onClose={handleClose}
      onClick={handleDrawerClick}
    >
      <Typography variant="h2">Assessments</Typography>
      <List>
        {assessments?.map((assessment, index) => (
          <ListItem id={assessment.id} key={`assessment-${index}`} disablePadding>
            {assessment.id === assessmentId ? (
              assessmentItem(assessment)
            ) : (
              <ListItemButton onClick={handleOpen(assessment)}>
                {assessmentItem(assessment)}
              </ListItemButton>
            )}
            <ListItemSecondaryAction>
              {assessment.id === assessmentId ? (
                <Tooltip title="Currently Open" placement="left">
                  <IconButton>
                    <OpenInBrowser fontSize='small' />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Delete Assessment" placement="left">
                  <IconButton onClick={handleDelete(assessment)}>
                    <Delete fontSize='small' />
                  </IconButton>
                </Tooltip>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default Assessments;
