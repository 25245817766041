import { ContentfulClientApi, Entry, EntryCollection, createClient } from 'contentful';
import { CapabilityModel, CompetencyModel, ContentfulModel, LevelModel, SkillLevelModel, SkillModel, TitleModel } from '~/models';
import { Tag } from '~/components/Context/DataContext';
import StorageService from './Storage';
import env from './Env';
import { Logger } from '~/helpers/LoggingHelper';

interface ModelMap {
  [key: string]: typeof ContentfulModel
}

export enum ContentType {
  Capability = 'capability',
  Competency = 'competency',
  Level = 'level',
  Skill = 'skill',
  SkillLevel = 'skillLevel',
  Title = 'title',
}

const modelMap: ModelMap = {
  'capability': CapabilityModel,
  'competency': CompetencyModel,
  'level': LevelModel,
  'skill': SkillModel,
  'skillLevel': SkillLevelModel,
  'title': TitleModel,
}

class ContentService {
  private storageService = new StorageService();
  private entryCache = new Map<string, Entry>();
  private contentTypeCache = new Map<string, EntryCollection<any>>();
  private client: ContentfulClientApi<any>;

  constructor() {
    const config = {
      // This is the space ID. A space is like a project folder in Contentful terms
      space: env(`CONTENTFUL_SPACE_ID`),
      // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
      accessToken: env('CONTENTFUL_APP_TOKEN'),
    };
    this.client = createClient(config);
  }

  public async getContentByTag<T = any>(tag: string): Promise<T> {
    const results = await this.getEntriesByTag(tag);
    return results;
  }

  public async getContent<ContentfulModel>(model: new(entry: Entry<any>) => ContentfulModel, entryId: string) {
    const result = await this.getEntry(entryId);
    const contentModel = new model(result!);

    return contentModel;
  }

  public async getContentType<ContentfulModel>(model: new(entry: Entry<any>) => ContentfulModel, contentType: ContentType) {
    // check content cache
    const pathName = String.trimStart(window.location.pathname, '/');
    const tag = Tag[pathName];
    const content = await this.storageService.getItem<EntryCollection<any>>(tag);
    const contentByType = content?.items.filter(i => i.sys.contentType.sys.id === contentType);

    if (contentByType) {
      return contentByType.map(c => new model(c))
    }

    // fetch content explicitly
    const results = await this.getEntriesByContentType(contentType);
    return results?.items.map((result: Entry) => {
      return new model(result);
    })
  }

  public getContentModel<T>(model: new(entry: Entry<any>) => ContentfulModel, entry: Entry<any>) {
    const contentType = entry.sys.contentType.sys.id;
    if (modelMap[contentType] !== model) {
      return null;
    }
    return new model(entry) as T;
  }

  private async getEntry(id: string) {
    if (!this.entryCache.has(id)) {
      try {
        const result = await this.client.getEntry(id);
        this.entryCache.set(id, result);
      } catch (e) {
        return null;
      }
    }

    return this.entryCache.get(id);
  }

  private async getEntriesByTag(tag: string): Promise<any> {
    const hasItem = await this.storageService.hasItem(tag)

    if (!hasItem) {
      try {
        const results = await this.client.getEntries({ 'metadata.tags.sys.id[in]': [tag], limit: 1000 })
        await this.storageService.setItem(tag, results);
      } catch (e) {
        Logger.error(e);
        return null;
      }
    }

    return this.storageService.getItem(tag);
  }

  private async getEntriesByContentType(contentType: ContentType) {
    if (!this.contentTypeCache.has(contentType)) {
      try {
        const results = await this.client.getEntries({ content_type: contentType, limit: 1000 })
        this.contentTypeCache.set(contentType, results);
      } catch (e) {
        return null;
      }
    }

    return this.contentTypeCache.get(contentType);
  }
}


export default ContentService;
