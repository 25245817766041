import { MutableRefObject } from 'react';
import { NavigateFunction } from 'react-router';
import { AssessmentModel, CommentModel, IAssessment } from '~/models';
import { AssessmentService } from '~/services';
import { IAuthContextState, IDataContextState, Setter } from '~/components/Context';
import { IInputHook } from '~/hooks/useInputValue';

interface HandlerDependencies {
  assessmentData: IAssessment;
  assessmentService: MutableRefObject<AssessmentService>;
  authContext: IAuthContextState;
  dataContext: IDataContextState;
  navigate: NavigateFunction;
  onClose: any;
  rename: boolean;
  renameInput: IInputHook<string>;
  setAssessmentData: Setter<IAssessment>;
  setRename: Setter<boolean>;
}

export const useHandlers = ({
  assessmentData,
  assessmentService,
  authContext,
  dataContext,
  navigate,
  onClose,
  rename,
  renameInput,
  setAssessmentData,
  setRename,
}: HandlerDependencies) => {
  const handleClose = () => {
    const [title] = dataContext.titleState;

    // reset input
    if (rename) {
      renameInput.onChange({ target: { value: assessmentData.Name || title.name }});
      setRename(false);
    }

    if (onClose) {
      onClose.call(this);
    }
  };

  const handleDelete = (assessment: IAssessment) => async (e) => {
    const [assessments, setAssessments] = dataContext.assessmentsState;
    const [_, setNotification] = dataContext.notificationState;

    try {
      const result = await assessmentService.current.deleteAssessment(assessment?.id, assessment?.Capability.id);
      if (result) {
        setAssessments(assessments.filter(x => x.id !== assessment.id));
      }

      setNotification({
        open: true,
        message: 'Assessment deleted',
        type: 'success',
      });
    } catch (error) {
      setNotification({
        open: true,
        message: 'Error deleting assessment',
        type: 'error',
      });
    }

    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrawerClick = () => {
    const [title] = dataContext.titleState;

    // reset input
    if (rename) {
      renameInput.onChange({ target: { value: assessmentData.Name || title.name }});
      setRename(false);
    }
  };

  const handleOpen = (assessment: IAssessment) => () => {
    const url = `/assessments/${assessment.id}`;

    navigate(url);

    onClose.call(this);
  };

  const handleRename = (e: any) => {
    e.stopPropagation();
    setRename(true);
  };

  const handleRenameKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleRenameSave(e);
    }
  };

  const handleRenameSave = (e: any) => {
    e.stopPropagation();
    setAssessmentData({
      ...assessmentData,
      Name: renameInput.value,
    });

    setRename(false);
  };

  const handleSave = async () => {
    const { commentInputRef } = dataContext;
    const [_assessment, setAssessment] = dataContext.assessmentState;
    const [assessments, setAssessments] = dataContext.assessmentsState;
    const [capability] = dataContext.capabilityState;
    const [competencies] = dataContext.competenciesState;
    const [_notification, setNotification] = dataContext.notificationState;

    const assessmentToSave = {
      ...assessmentData,
      Capability: capability,
      Competencies: competencies,
      CreatedDate: new Date(),
    };

    const selectedCompetency = competencies?.filter(c => c.selected === true)[0];
    const comment = commentInputRef.current.value;

    assessmentToSave.Competencies.forEach(c => {
      if (c.id === selectedCompetency.id && comment.length){
        c.comments.push(new CommentModel(authContext.user.name, new Date().toISOString(), comment));
      } else if (c.unsavedComment) {
        c.comments.push(c.unsavedComment);
      }
      c.unsavedComment = undefined;
    });

    setAssessmentData(assessmentToSave);

    try {
      const newAssessment = await assessmentService.current.saveAssessment(
        new AssessmentModel(assessmentToSave)
      );

      if (newAssessment) {
        setAssessment(newAssessment);
        setAssessments([
          ...assessments,
          newAssessment,
        ]);

        const url = `/assessments/${newAssessment.id}`;

        navigate(url);

        setNotification({
          open: true,
          message: 'Assessment saved!',
          type: 'success',
        });
      }
    } catch (error) {
      setNotification({
        open: true,
        message: 'Error savng assessment',
        type: 'error',
      });
    }
  };

  return {
    handleClose,
    handleDelete,
    handleDrawerClick,
    handleOpen,
    handleRename,
    handleRenameKeyDown,
    handleRenameSave,
    handleSave,
  }
}