import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Avatar, Paper, Tooltip, Typography } from '@mui/material';
import { ContentHelper } from '~/helpers';
import { TitleModel } from '~/models/Title';
import { DataContext } from '~/components/Context';
import './CapabilityInfo.scss';

const CapabilityInfo = () => {
  const dataContext = useContext(DataContext);
  const [assessment] = dataContext.assessmentState;
  const [capability] = dataContext.capabilityState;
  const [content] = dataContext.contentState;
  const [title, setTitle] = dataContext.titleState;
  const [titles, setTitles] = useState<TitleModel[]>([]);

  useEffect(() => {
    const getTitles = async () => {
      const titleData = ContentHelper.getContent<TitleModel>(content, TitleModel);
      const titles = titleData
        .filter(t => !!t.capability.find(c => c.slug === capability?.slug))
        .orderBy<TitleModel>(t => t.order)
        .map((t, idx) => {
          t.selected = assessment ? assessment.Title.id === t.id : idx === 0;
          return t;
        });

      setTitles(titles)
      setTitle && setTitle(titles.find(t => t.selected));
    }

    if (capability) {
      getTitles();
    } else {
      setTitles(undefined);
    }
  }, [assessment, capability])

  const handleTitleClick = (e: SyntheticEvent) => {
    const { target } = e.nativeEvent as any;
    const { parentElement } = target;
    if (parentElement?.dataset['title']) {
      const entry = JSON.parse(parentElement.dataset['title']);
      const title = new TitleModel(entry);
      title.selected = true;

      // set selected title
      titles.forEach(t => t.selected = t.abbr === title.abbr);

      setTitle && setTitle(title);
      setTitles(titles)
    }
  }

  const getCapabilityDescription = () => {
    const capabilityDescriptions = title?.capabilityDescriptions?.find(cd => cd.capability.id === capability?.id);
    return capabilityDescriptions?.description!;
  }

  return (
    <div id="capability-info">
      <label>Title</label>
      <ul className="titles">
        {titles?.map(title => (
          <li
            key={title.id}
            className={title.selected ? 'selected' : ''}
            data-title={JSON.stringify(title)}
            onClick={handleTitleClick}
          >
            <Tooltip title={title.name} placement="top-start">
              <Avatar>{title.abbr}</Avatar>
            </Tooltip>
          </li>
        ))}
      </ul>
      <Paper elevation={0}>
        <Typography variant="h2">{title?.name}</Typography>
        <Typography variant="body1" dangerouslySetInnerHTML={{
          __html: title?.description
        }} />
        <Typography variant="body1" dangerouslySetInnerHTML={{
          __html: getCapabilityDescription()
        }} />
      </Paper>
    </div>

  )
}

export default CapabilityInfo;
