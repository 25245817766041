import { useContext, useEffect, useMemo, useState } from 'react';
import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, Select, Typography } from '@mui/material';
import { Logout, Person } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { EntryCollection } from 'contentful';
import { ContentHelper } from '~/helpers';
import { CapabilityModel } from '~/models';
import { ContentService, StorageService } from '~/services';
import { AuthContext, DataContext } from '~/components/Context';
import spiderChart from '~/images/spider-chart.svg';
import logo from '~/images/digimarc-icon.svg';
import './Header.scss';

const Header = () => {
  const { msal, user } = useContext(AuthContext);
  const dataContext = useContext(DataContext);
  const [title, setTitle] = useState('Unknown');
  const [selfAssessmentData, setSelfAssessmentData] = useState(null);
  const [assessment] = dataContext.assessmentState;
  const [_showAssessments, setShowAssessments] = dataContext.showAssessmentsState;
  const [_capability, setCapability] = dataContext.capabilityState!;
  const [_content, setContent] = dataContext.contentState!;
  const [selectedCapability, setSelectedCapability] = useState('Select');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const contentService = new ContentService();
  const storageService = new StorageService();

  useEffect(() => {
    const getData = async () => {
      const data = await storageService.getItem<any>('selfAssessment');
      setSelfAssessmentData(data);
    }

    getData();
  }, []);

  useEffect(() => {
    if (assessment) {
      const e = {
        target: {
          value: assessment?.Capability?.tag
        }
      }
      handleCapabilityChange(e);
    }
  }, [assessment])

  useMemo(() => {
    if (_capability) {
      setTitle(_capability.name);
    }
  }, [_capability]);

  const handleCapabilityChange = async (e: any) => {
    const { value:tag } = e.target;

    // get the content
    const content = await contentService.getContentByTag<EntryCollection<any>>(tag);

    // set the capability-specific storage data
    await storageService.setItem(tag, content);

    // set the DataContext
    setContent(content);

    // get the capability
    const capabilities = ContentHelper.getContent!<CapabilityModel>(content, CapabilityModel);
    const capability = capabilities?.find(c => c.tag === tag);

    // set the Data Context
    setCapability(capability);

    // set the Dropdown value
    setSelectedCapability(tag);
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAssessments = () => {
    setShowAssessments(true);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    msal?.logout();
  }

  return (
    <>
      {user
        ? (
          <header>
            <Typography variant="h1">
              <sup>Capability</sup>
              { selfAssessmentData && !assessment ? (
                <Select
                  value={selectedCapability || 'Select'}
                  onChange={handleCapabilityChange}
                >
                  <MenuItem value="Select">Select</MenuItem>
                  { selfAssessmentData.capabilities.map((c: any) => (
                    <MenuItem key={c.id} value={c.fields.tag}>{c.fields.name}</MenuItem>
                  ))}
                </Select>
              ) : title}
            </Typography>
            <Link to="/">
              <img src={logo} className="logo" alt="Digimarc Logo" />
            </Link>

            <IconButton
              onClick={handleAvatarClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar
                  alt={user.name}
                  sx={{
                    height: 32,
                    width: 32,
                  }}
                >
                  <Person />
              </Avatar>
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleMenuClose}
              onClick={handleMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {user && (
                <MenuItem disabled={true}>
                  {user.username}
                </MenuItem>
              )}   
              <MenuItem onClick={handleAssessments}>
                <ListItemIcon>
                  <img src={spiderChart} className="spider-chart" alt="Spider Chart Icon" />
                </ListItemIcon>
                Assessments
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>

          </header>
        )
        : null}
    </>
  )
}

export default Header;
