import { Logger } from '~/helpers/LoggingHelper';

class Storage {
  static db: IDBDatabase;

  static async ready(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      if (this.db) {
        return resolve(this.db);
      }

      const openRequest = indexedDB.open('CareerMatrix', 1);

      openRequest.onerror = () => {
        Logger.error('Error loading database', openRequest.error);
        reject({ error: openRequest.error });
      }

      openRequest.onsuccess = () => {
        this.db = openRequest.result
        resolve(this.db);
      }

      // This event handles when a new version of the database needs to be created
      // Either one has not been created before, or a new version number has been
      // submitted via the indexedDB.open line above. It is only implemented in recent browsers
      openRequest.onupgradeneeded = (e: IDBVersionChangeEvent) => {
        // Create an objectStore for this database
        const target: any = e.target;
        this.db = target.result;

        this.db.onerror = (err) => {
          Logger.error('Error loading database.', err);
        };

        const objectStore = this.db.createObjectStore('CareerMatrix');

        // Define what data items the objectStore will contain
        objectStore.createIndex('key', 'key', { unique: true });
        objectStore.createIndex('value', 'value', { unique: false });

        resolve(this.db);
      };
    });
  }
}

class StorageService {

  constructor() {}

  async getItem<T>(key: string): Promise<T | null> {
    const storage = await Storage.ready();

    return new Promise((resolve, reject) => {
      if (!key) {
        resolve(undefined);
      }

      const transaction = storage.transaction(['CareerMatrix'], 'readwrite');
      const objectStore = transaction.objectStore('CareerMatrix');
      const query = objectStore.get(key);

      query.addEventListener('success', () => resolve(query.result));

      transaction.addEventListener('error', () => {
        Logger.error('Transaction error', query.error);
        reject(query.error);
      });
    });
  }

  async hasItem(key: string): Promise<boolean> {
    const storage = await Storage.ready();

    return new Promise(resolve => {
      const transaction = storage.transaction(['CareerMatrix'], 'readwrite');
      const objectStore = transaction.objectStore('CareerMatrix');
      const query = objectStore.get(key);

      query.addEventListener('success', () => {
        resolve(!!query.result);
      });

      transaction.addEventListener('error', () => {
        Logger.error('Transaction error', query.error);
        resolve(false)
      });
    });
  }

  async removeItem(key: string) {
    const storage = await Storage.ready();

    return new Promise(resolve => {
      const transaction = storage.transaction(['CareerMatrix'], 'readwrite');
      const objectStore = transaction.objectStore('CareerMatrix');

      objectStore.delete(key);

      transaction.addEventListener('complete', () => resolve(true));
      transaction.addEventListener('error', () => {
        Logger.log('Transaction error', transaction.error);
        resolve(false);
      });
    });
  }

  async setItem(key: string, value: any) {
    const storage = await Storage.ready();

    return new Promise(resolve => {
      const transaction = storage.transaction(['CareerMatrix'], 'readwrite');
      const objectStore = transaction.objectStore('CareerMatrix');
      const query = objectStore.put(value, key);

      query.addEventListener('success', () => {
        resolve(true)
      });

      transaction.addEventListener('error', () => {
        Logger.error('Transaction error', query.error);
        resolve(false);
      });
    });
  }
}

export default StorageService;
