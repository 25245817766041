import env from '~/services/Env';
import { EnumHelper } from './EnumHelper';

export enum LogLevel {
  INFO = 0,
  TRACE = 1,
  WARNING = 2,
  ERROR = 3,
  OFF = 4,
}

type logging = (message?: any, ...optionalParams: any[]) => void;
const logger = (level: LogLevel): logging => {
  const logLevel = env('LOG_LEVEL') || LogLevel.ERROR;
  const environmentLevel: LogLevel = EnumHelper.parse(LogLevel, logLevel);
  const fn = {
    [LogLevel.INFO]: 'log',
    [LogLevel.ERROR]: 'error',
    [LogLevel.TRACE]: 'trace',
    [LogLevel.WARNING]: 'warn',
  }[level];

  return fn && level >= environmentLevel ? Function.prototype.bind.call(console[fn], console) : () => {};
}

// const BaseLogger = (...args: any[]): void => {
//   const log = logger(LogLevel.INFO);
//   log(...args);
// };

export const Logger = {
  // ...BaseLogger,
  debug(...args: any[]): void {
    const log = logger(LogLevel.INFO);
    log(...args);
  },
  error(...args: any[]): void {
    const log = logger(LogLevel.ERROR);
    log(...args);
  },
  info(...args: any[]): void {
    const log = logger(LogLevel.INFO);
    log(...args);
  },
  log(...args: any[]): void {
    const log = logger(LogLevel.INFO);
    log(...args);
  },
  warn(...args: any[]): void {
    const log = logger(LogLevel.WARNING);
    log(...args);
  },
  verbose(...args: any[]): void {
    const log = logger(LogLevel.TRACE);
    log(...args);
  }
};
