import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  useTheme
} from '@mui/material';
import { ChatOutlined } from '@mui/icons-material';
import { ResponsiveContainer } from 'recharts';
import { useInputValue, useLoaderData } from '~/hooks';
import { ContentHelper } from '~/helpers';
import { AssessmentService } from '~/services';
import { CommentModel, CompetencyModel, SkillLevelModel, SkillModel } from '~/models';
import { ISelfAssessmentLoaderData } from '~/pages/SelfAssessment';
import { AuthContext, DataContext } from '~/components/Context';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar } from '~/components/Chart';
import { RadarChart } from '~/components/Chart/RadarChart';
import { Skills } from '~/components/Skills';
import { useHandlers } from './Competencies.handlers';
import './Competencies.scss';

const Competencies = () => {
  const theme = useTheme();
  const authContext = useContext(AuthContext);
  const dataContext = useContext(DataContext);
  const { commentInputRef } = dataContext;
  const [title] = dataContext.titleState;
  const [content] = dataContext.contentState;
  const [capability] = dataContext.capabilityState;
  const [competencies, setCompetencies] = dataContext.competenciesState;
  const { isSelfAssessment, isAssessmentComplete, assessment } = useLoaderData<ISelfAssessmentLoaderData>();
  const [selectedCompetency, setSelectedCompetency] = useState<CompetencyModel>(undefined);
  const [selectedCompetencyIndex, setSelectedCompetencyIndex] = useState(0);
  const [comments, setComments] = useState<CommentModel[]>([]);
  const [skills, setSkills] = useState<SkillModel[]>([]);
  const [skill, setSkill] = useState<SkillModel | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [assessmentError, setAssessmentError] = useState<boolean>(false);
  const commentInput = useInputValue<string>('');
  const assessementName = useInputValue<string>('');
  const assessmentService = useRef(new AssessmentService());
  const navigate = useNavigate();
  const {
    handleAxisClick,
    handleChartClick,
    handleChange,
    handleModalClose,
    handleSave,
  } = useHandlers({
    assessment,
    assessementName,
    assessmentService,
    authContext,
    capability,
    commentInput,
    dataContext,
    isAssessmentComplete,
    navigate,
    selectedCompetencyIndex,
    setAssessmentError,
    setComments,
    setModalOpen,
    setSaving,
    setSelectedCompetencyIndex,
    setSkill,
    setSkills,
    skills,
  })

  useEffect(() => {
    setCompetencies(undefined);
    setSelectedCompetency(undefined);
    setSelectedCompetencyIndex(0);
  }, []);

  useEffect(() => {
    const getData = () => {
      let competencies = ContentHelper.getContent<CompetencyModel>(content, CompetencyModel);
      const skillData = ContentHelper.getContent<SkillModel>(content, SkillModel);
      const skillLevelData = ContentHelper.getContent<SkillLevelModel>(content, SkillLevelModel);
      const skillLevels = skillLevelData?.filter(sl => sl.title?.abbr === title?.abbr);

      if (competencies?.length) {
        // order & map skills to competencies
        competencies = competencies
          .orderBy<CompetencyModel>(c => c.order)
          .map(c => {
            c.skills = skillData ?
              skillData
                .filter(s => s.competency.id === c.id)
                .map(s => {
                  const skillLevel = skillLevels?.find(sl => sl.skill?.id === s.id);
                  const skillAssessment = assessment?.Competencies.reduce<SkillModel>((result, competency) => {
                    const skill = competency.skills.find(x => x.id === s.id);
                    if (skill) {
                      result = skill;
                    }

                    return result;
                  }, undefined);

                  return {
                    id: s.id,
                    sys: { id: s.id },
                    fields: s.fields,
                    competency: s.competency,
                    description: s.description,
                    levels: s.levels,
                    name: s.name,
                    value: skillLevel?.level?.value,
                    assessment: skillAssessment?.assessment,
                  }
                }) :
              [];
            return c;
          })

        const competency = competencies.elementAt<CompetencyModel>(selectedCompetencyIndex);
        competency.selected = true;
        setSkills(competency.skills);
        setSelectedCompetency(competency);
        setSelectedCompetencyIndex(selectedCompetencyIndex);

        const assessmentComments = assessment
          ? assessment?.Competencies.elementAt<CompetencyModel>(selectedCompetencyIndex)?.comments || []
          : [];

        setComments(assessmentComments);
      }

      setCompetencies(competencies);
    }

    if (title) {
      getData();
    }

    if (assessment) {
      commentInput.onChange({ target: { value: '' } });
      assessementName.onChange({ target: { value: assessment.Name } });
    }

  }, [assessment, title]);

  useEffect(() => {
    const competency = competencies?.elementAt<CompetencyModel>(selectedCompetencyIndex)

    if (competency) {
      competencies?.forEach(c => c.selected = c.name === competency.name);
      setCompetencies(competencies);
      setSelectedCompetency(competency);
      setSkills(competency.skills);

      const assessmentComments = assessment
        ? assessment?.Competencies.elementAt<CompetencyModel>(selectedCompetencyIndex)?.comments || []
        : [];

      setComments(assessmentComments);
    }
  }, [selectedCompetencyIndex]);

  const assessmentName = () => {
    return assessment?.id ?
      <div className="assessment-name">
        <span className="title">{assessementName.value}</span>
        <span className="date">{new Intl.DateTimeFormat(navigator.language).format(assessment.CreatedDate)}</span>
      </div>
      :
      <TextField
        required
        error={assessmentError}
        className="assessment-name-input"
        label="Enter Assessment Name"
        size="small"
        {...assessementName}
      />
    ;
  };

  return (
    <div id="competencies">
      <label>Competencies</label>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <AppBar color="default" position="static">
          <Tabs
            value={selectedCompetencyIndex}
            onChange={handleChange}
            textColor="inherit"
            variant='fullWidth'
            TabIndicatorProps={{
              style: {
                backgroundColor: 'transparent'
              }
            }}
          >
            {competencies?.map((competency, idx) => (
              <Tab
                key={idx}
                className={assessmentError && !competency.skills.every(s => !!s.assessment) ? 'assessment-error' : ''}
                label={assessmentError && !competency.skills.every(s => !!s.assessment) ? competency.name + '*' : competency.name} />
            ))}
          </Tabs>
        </AppBar>
      </Box>
      <Paper>
        <label>Skills</label>
        <ResponsiveContainer height={550} width="100%">
          <RadarChart id="chart" outerRadius="50%" data={skills} onClick={handleChartClick}>
            <PolarGrid gridType='polygon' />
            <PolarRadiusAxis type="number" domain={[0, 5]} tickCount={6} axisLine={false} tick={false} />
            <PolarAngleAxis dataKey="name" onClick={handleAxisClick} />
            <Radar
              animationDuration={100}
              dataKey="value"
              fill={theme.palette.primary.main}
              fillOpacity={0.5}
              stroke="black"
            />
            {isSelfAssessment ?
              <Radar
                animationDuration={100}
                dataKey="assessment"
                dot={{ stroke: 'black', strokeWidth: 1, r: 4, strokeDasharray: '' }}
                fill={theme.palette.secondary.main}
                fillOpacity={0.5}
                stroke="black"
              />
              : null
            }
          </RadarChart>
        </ResponsiveContainer>
      </Paper>
      {comments.length ?
        <Accordion className="comments-accordion">
          <AccordionSummary className="comments-header" expandIcon={<ChatOutlined />}>
            {selectedCompetency?.name} Comments <sup>(click to expand)</sup>
          </AccordionSummary>
          <AccordionDetails className="comments-details">
            <List className="comments-list">
              {comments?.map((comment, idx) => (
                <ListItem key={idx} className="comments-item">
                  <div className="user-date">
                    <span className="user">{comment.user}</span>
                    <span className="date">{new Date(comment.createdAt).toLocaleDateString()}</span>
                  </div>
                  <span className="comment">{comment.text}</span>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
        : null
      }
      {(isSelfAssessment && !!title) ? (
        <>
          <Box className="comments">
            <Box className="input">
              <TextField
                inputRef={commentInputRef}
                label={`Add a ${selectedCompetency?.name} Comment`}
                multiline
                rows={3}
                {...commentInput}
              />
            </Box>
          </Box>
          <Box className="save">
            {assessmentName()}
            {saving ? (
              <CircularProgress />
            ) : (
              <Button onClick={handleSave}>Save</Button>
            )}
          </Box>
        </>
      ) : null}
      <Skills
        skill={skill}
        open={modalOpen}
        isSelfAssessment={isSelfAssessment}
        onClose={handleModalClose}
      />
    </div>
  )
}

export default Competencies;
