import { useState } from 'react';

interface IEventTarget {
  value: string;
}

interface IChangeEvent {
  target: IEventTarget;
}

export interface IInputHook<T> {
  value: T,
  onChange: (e: IChangeEvent) => void,
}

const useInputValue = <T>(initialValue = undefined): IInputHook<T> => {
  const [value, setValue] = useState<T>(initialValue);

  return {
    value,
    onChange: (e: IChangeEvent) => {
      const { target } = e;
      setValue(target?.value as T);
    },
  };
};

export default useInputValue;
