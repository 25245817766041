import { MutableRefObject, SyntheticEvent } from 'react';
import { NavigateFunction } from 'react-router';
import { AssessmentModel, CapabilityModel, CommentModel, CompetencyModel, SkillModel } from '~/models';
import { IAuthContextState, IDataContextState, Setter } from '~/components/Context';
import { ChartState } from '~/components/Chart/CategoricalChart';
import { IInputHook } from '~/hooks/useInputValue';
import { AssessmentService } from '~/services';
import { Logger } from '~/helpers/LoggingHelper';

interface HandlerDependencies {
  assessment: AssessmentModel;
  assessementName: IInputHook<string>;
  assessmentService: MutableRefObject<AssessmentService>;
  authContext: IAuthContextState;
  capability: CapabilityModel;
  commentInput: IInputHook<string>;
  dataContext: IDataContextState,
  isAssessmentComplete: boolean;
  navigate: NavigateFunction;
  selectedCompetencyIndex: number;
  setAssessmentError: Setter<boolean>;
  setComments: Setter<CommentModel[]>;
  setModalOpen: Setter<boolean>;
  setSaving: Setter<boolean>;
  setSelectedCompetencyIndex: Setter<number>;
  setSkill: Setter<SkillModel>;
  setSkills: Setter<SkillModel[]>;
  skills: SkillModel[];
}

export const useHandlers = ({
  assessment,
  assessementName,
  assessmentService,
  authContext,
  capability,
  commentInput,
  dataContext,
  isAssessmentComplete,
  navigate,
  selectedCompetencyIndex,
  setAssessmentError,
  setComments,
  setModalOpen,
  setSaving,
  setSelectedCompetencyIndex,
  setSkill,
  setSkills,
  skills,
}: HandlerDependencies) => {
  const handleAxisClick = (e: any) => {
    const { value } = e;
    const skill = skills.find(s => s.name === value);

    setSkill(skill);
    setModalOpen(true);
  }

  const handleChartClick = (e: ChartState) => {
    const [competencies] = dataContext.competenciesState;
    const [_, setAssessmentComplete] = dataContext.assessmentCompleteState;

    Logger.log(isAssessmentComplete);
    Logger.log('-----------Chart Click------------')

    if (e && !isAssessmentComplete) {
      const skill = skills.find(s => s.name === e.activeLabel);
      if (skill) {
        setSkills(skills.map(s => {
          if (s.id === skill.id) {
            s.assessment = e.activeTick;
          }
          return s;
        }));

        const competency = competencies?.elementAt<CompetencyModel>(selectedCompetencyIndex);
        competency.unsavedComment = new CommentModel(authContext.user.name, new Date().toISOString(), commentInput.value);;

        setAssessmentComplete(competencies.reduce<boolean>((result, competency) => {
          const complete = competency.skills.every(s => !!s.assessment);
          return result && complete;
        }, true));
      }
    }
    Logger.log(isAssessmentComplete);
  }

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    const [competencies] = dataContext.competenciesState;

    //update current comment
    let competency = assessment
      ? assessment.Competencies?.elementAt<CompetencyModel>(selectedCompetencyIndex)
      : competencies?.elementAt<CompetencyModel>(selectedCompetencyIndex);
    competency.unsavedComment = new CommentModel(authContext.user.name, new Date().toISOString(), commentInput.value);

    setSelectedCompetencyIndex(newValue);

    // update new comments
    competency = assessment ? assessment.Competencies?.elementAt<CompetencyModel>(newValue) : competencies?.elementAt<CompetencyModel>(newValue);
    setComments(competency?.comments);
    commentInput.onChange({
      target: {
        value: competency.unsavedComment ? competency.unsavedComment?.text : ''
      }
    });
  }

  const handleModalClose = (skillId: string, value: any) => {
    if (!isAssessmentComplete) {
      setSkills(skills.map(s => {
        if (s.id === skillId) {
          s.assessment = value;
        }
        return s;
      }));
    }
    setModalOpen(false);
  }

  const handleSave = () => {
    assessment?.id ? handleSaveComment() : handleSaveAssessment();
  }

  const handleSaveComment = async () => {
    const [competencies] = dataContext.competenciesState;
    const [_, setNotification] = dataContext.notificationState;
    const competency = assessment
      ? assessment.Competencies?.elementAt<CompetencyModel>(selectedCompetencyIndex)
      : competencies?.elementAt<CompetencyModel>(selectedCompetencyIndex);
    const comment = commentInput.value;

    competency.comments.push({
      user: authContext.user.name,
      createdAt: new Date().toISOString(),
      text: comment,
    });

    // show spinner
    setSaving(true);

    // save comments
    await assessmentService.current.saveAssessmentComments(assessment);

    setNotification({
      open: true,
      message: 'Comment saved!',
      type: 'success',
    });

    // clear input
    commentInput.onChange({ target: { value: '' } });

    // update state
    setComments(competency.comments);

    // hide spinner
    setSaving(false);
  }

  const handleSaveAssessment = async () => {
    setAssessmentError(false);

    const [_, setNotification] = dataContext.notificationState;
    const [assessmentComplete] = dataContext.assessmentCompleteState;
    const [assessments, setAssessments] = dataContext.assessmentsState;
    const [competencies] = dataContext.competenciesState;
    const [title] = dataContext.titleState;

    Logger.log(isAssessmentComplete);

    if (!assessementName?.value.length) {
      setAssessmentError(true);
      setNotification({
        open: true,
        message: 'Please enter an Assessment Name.',
        type: 'error',
      });
    } else if (!assessmentComplete) {
      setAssessmentError(true);
      setNotification({
        open: true,
        message: 'Assessment is not complete. Please complete before saving.',
        type: 'error',
      });
    } else {
      const assessmentToSave = new AssessmentModel({
        ...assessment,
        Name: assessementName.value,
        Capability: capability,
        Competencies: competencies,
        CreatedDate: new Date(),
        Title: title,
        UserId: authContext.user.localAccountId
      });

      assessmentToSave.Competencies.forEach(c => {
        const [selected] = competencies?.filter(c => c.selected === true);
        if (c.id === selected.id && commentInput) {
          c.comments.push(new CommentModel(authContext.user.name, new Date().toISOString(), commentInput.value));
        } else if (c.unsavedComment) {
          c.comments.push(c.unsavedComment);
        }
        c.unsavedComment = undefined;
      });

      try {
        const newAssessment = await assessmentService.current.saveAssessment(assessmentToSave);

        if (newAssessment) {
          setAssessments([
            ...assessments,
            newAssessment,
          ]);

          navigate(`/assessments/${newAssessment.id}`);

          setNotification({
            open: true,
            message: 'Assessment saved!',
            type: 'success',
          });
        }
      } catch (error) {
        setNotification({
          open: true,
          message: 'Error savng assessment',
          type: 'error',
        });
      }
    }
  };


  return {
    handleAxisClick,
    handleChartClick,
    handleChange,
    handleModalClose,
    handleSave,
    handleSaveComment,
    handleSaveAssessment,
  }
};
