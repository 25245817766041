import { useContext, useEffect, useState } from 'react';
import { List, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { ContentType } from '~/services';
import { CapabilityModel } from '~/models/Capability';
import { DataContext } from '~/components/Context';
import logo from '~/images/digimarc-icon-white.svg';
import './Home.scss';

const Home = () => {
  const dataContext = useContext(DataContext);
  const [capabilities, setCapabilities] = useState<CapabilityModel[]>([]);
  const { contentService } = dataContext;

  useEffect(() => {
    const getData = async () => {
      const capabilityData = await contentService?.getContentType(CapabilityModel, ContentType.Capability);
      setCapabilities(capabilityData.orderBy(x => x.name));
    }

    // on load, clear out any existing data
    dataContext.reset();

    getData();
  }, []);

  return (
    <section id="home">
      <header>
        <img src={logo} className="logo" alt="logo" />
      </header>
      <main>
        <p>
          Welcome to the Digimarc Career Matrix.
        </p>
        <List>
          {capabilities.map(capability => (
            <ListItem key={capability.id}>
              <Link
                className="link"
                to={`/${capability.slug}`}
              >
                {capability.name}
              </Link>
            </ListItem>
          ))}
          <ListItem>
            <Link className="link" to='/assessments'>Assessments</Link>
          </ListItem>
        </List>
      </main>
    </section>
  )
};

export default Home;
