import { createContext } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

export interface IAuthContextState {
  msal?: IPublicClientApplication | undefined;
  user?: AccountInfo | undefined;
}

export interface IAuthProviderModel {
  msal: IPublicClientApplication;
  children: any;
}

export const AuthContext = createContext<IAuthContextState>({});

export const AuthProvider: React.FC<IAuthProviderModel> = ({ msal, children }) => {
  const user = msal.getActiveAccount();

  return (
    <MsalProvider instance={msal}>
      <AuthContext.Provider
        value={{
          msal,
          user,
        }}
      >
        {children}
      </AuthContext.Provider>
    </MsalProvider>
  )
};
