export const EnumHelper = {
  parse: (enumeration: any, value: string | number) => {
    let val;
    if (enumeration.hasOwnProperty(value)) {
      val = enumeration[value];
    } else {
      val = Object.keys(enumeration).reduce((result, key) => {
        if (enumeration[key] === value) {
          result = key;
        }
        return result;
      });
    }
    return val;
  },
  values: (enumeration: any): any[] => {
    const defaultValue: any[] = [];

    return Object.keys(enumeration).reduce((result, value) => {
      let val = EnumHelper.parse(enumeration, value);

      if (typeof val === 'string') {
        val = EnumHelper.parse(enumeration, val);
      }

      if (result.indexOf(val) === -1) {
        result.push(val);
      }
      return result;
    }, defaultValue);
  }
};
